import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import bitscrunchLogo from "../../../assets/images/bitscrunch-logo.png"
import NetworkDetails from "../../components/network-details";

const Bitscrunch = () => (
  <Layout>
    <Seo title="bitsCrunch" />
    <NetworkDetails
      logo={bitscrunchLogo}
      name="BCUT"
      commission="15%"
      totalStake="746,170 BCUT"
      address="0xf08ad37c9ce6e5a289a7dd493e9a71dbe7d11e7f"
    />
    <Link
      className="orange-button back-to-homepage"
      style={{
        maxWidth: `45%`,
        margin: `80px auto 0`,
        textAlign: `center`,
        display: `block`,
        textDecoration: `none`
      }}
      to="/#networks"
    >
      Back to homepage
    </Link>
  </Layout>
)

export default Bitscrunch
